<template>
  <div class="breadcrumb">
    <span v-if="!isCurrent" class="can-truncate">
      <em class="panel-from">from </em>
      <UtilityLink
        :href="`/write/document-${documentId}`"
        @click.prevent="openDocument"
      >
        {{ title }}
      </UtilityLink>
      »
    </span>
    <template v-if="isArchivedDocument">
      <span data-version class="details version-details can-truncate">
        Modified <UtilityDate :value="modifiedAt" />
      </span>
      |
      <span data-version class="details version-details can-truncate">
        Archived <UtilityDate :value="archivedAt" />
      </span>
    </template>
    <template v-else>
      <UtilityLink
        :href="`/write/document_versions-${documentId}`"
        :disabled="disableList"
        @click.prevent="openDocumentVersionsList"
      >
        Versions
      </UtilityLink>
      <span v-if="isCurrent" data-version="current">
        » <strong>Current</strong>
      </span>
      <span v-else data-version class="details version-details can-truncate">
        | <UtilityDate :value="modifiedAt" /> | {{ wordCount }}
        {{ wordCount === 1 ? 'word' : 'words' }}
      </span>
      <span
        v-if="isShared"
        data-version
        data-layout="details"
        class="details version-details"
      >
        |
        <IconButton
          icon-name="users"
          icon-size="small"
          :hidden-text="`Share ${isCurrent ? 'Document' : 'Version'}`"
          @click="$emit('share')"
        />
      </span>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';

import useDisableVersionsList from '@/js/composables/useDisableVersionsList';
import { navigatePanel } from '@/js/router/panels';

export default defineComponent({
  name: 'DocumentBreadcrumb',
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    documentId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    modifiedAt: {
      type: String,
      required: true,
    },
    archivedAt: {
      type: String,
      default: undefined,
    },
    wordCount: {
      type: Number,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    isHiddenVersion: {
      type: Boolean,
      default: false,
    },
    isArchivedDocument: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['share'],
  setup(props) {
    const { documentId } = toRefs(props);
    const disableList = useDisableVersionsList(documentId.value);

    return { disableList };
  },
  methods: {
    openDocument() {
      navigatePanel({
        to: `document-${this.documentId}`,
        panelIndex: this.panelIndex,
      });
    },
    openDocumentVersionsList() {
      if (this.disableList) return;
      navigatePanel({
        to: `document_versions-${this.documentId}`,
        panelIndex: this.panelIndex,
        state: { showHidden: this.isHiddenVersion || undefined },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.version-details {
  padding-inline-start: var(--quarter-shim);
}
</style>
