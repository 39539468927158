<template>
  <UtilityModal data-modal="archive" open @close="$emit('close')">
    <div class="modal-instructions">
      <span class="icon-offset">
        <BooksIcon name="archive" size="medium" appearance="inverted" />
      </span>
      <h2>Confirm Document Archive</h2>
      <p>
        You are about to archive all versions of the document
        <strong>{{ documentTitle }}</strong
        >.
      </p>
      <p
        v-if="documentIsShared && !hasSharedVersions"
        data-share-modal-text="small"
      >
        Archiving this document will delete its shared link and remove public
        access.
      </p>
      <p
        v-else-if="documentIsShared && hasSharedVersions"
        data-share-modal-text="small"
      >
        Archiving this document will delete the shared links to the current view
        and saved versions, and remove public access.
      </p>
      <p v-else-if="hasSharedVersions" data-share-modal-text="small">
        Archiving this document will delete the shared links to saved versions,
        and remove public access.
      </p>
    </div>
    <!-- <ul>
      <li>Collaborators' versions of this document will not be archived.</li>
      <li>You will still be a collaborator on this document.</li>
      <li>Visit the Archive to restore this document.</li>
    </ul> -->
    <div class="modal-actions">
      <button
        type="button"
        class="modal-action"
        data-btn="wide"
        :disabled="!connected || archiving"
        @click="doArchive"
      >
        Archive Document
      </button>
    </div>
  </UtilityModal>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';

import { closeDocumentPanels } from '@/js/router/panels';
import { useDocumentsStore } from '@/js/stores/documents';
import { useVersionsStore } from '@/js/stores/versions';

export default defineComponent({
  name: 'DocumentArchiveModal',
  props: {
    documentId: {
      type: Number,
      required: true,
    },
    documentTitle: {
      type: String,
      required: true,
    },
    documentIsShared: {
      type: Boolean,
      required: true,
    },
    connected: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      archiving: false,
    };
  },
  computed: {
    ...mapState(useVersionsStore, ['sharedVersionsByDoc']),
    hasSharedVersions() {
      return this.sharedVersionsByDoc(this.documentId).length > 0;
    },
  },
  methods: {
    ...mapActions(useDocumentsStore, ['archiveDocument']),
    async doArchive() {
      this.archiving = true;
      const archived = await this.archiveDocument(
        this.documentId,
        this.documentTitle,
      );
      this.archiving = false;
      // In practice, emitting `close` is not used by the List menu.
      // `archiveDocument` above removes the document from the store, and the
      // parent `list` component removes this modal when it is removed from the
      // store. The Document Detail menu does use this event.
      this.$emit('close');
      if (archived) {
        closeDocumentPanels(this.documentId);
      }
    },
  },
});
</script>
