<template>
  <UtilityMenu>
    <template v-if="documentId !== null && !isList && !isArchivedDocument">
      <!-- <UtilityMenuButton disabled>Full Screen</UtilityMenuButton> -->
      <UtilityMenuLink
        :href="`/write/document_versions-${documentId}`"
        :disabled="disableList"
        @click.prevent="openDocumentVersionsList"
        >View Versions List</UtilityMenuLink
      >
    </template>
    <UtilityMenuButton
      :disabled="isList && disableList"
      @click="duplicatePanel"
    >
      Open Duplicate Panel
    </UtilityMenuButton>
    <UtilityMenuButton @click="closePanel">Close Panel</UtilityMenuButton>
    <slot />
  </UtilityMenu>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';

import useDisableVersionsList from '@/js/composables/useDisableVersionsList';
import { closePanel, duplicatePanel, navigatePanel } from '@/js/router/panels';

export default defineComponent({
  name: 'PanelHeaderMenu',
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    documentId: {
      type: Number,
      default: null,
    },
    isList: {
      type: Boolean,
      default: false,
    },
    isHiddenVersion: {
      type: Boolean,
      default: false,
    },
    isArchivedDocument: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { documentId } = toRefs(props);
    const disableList = useDisableVersionsList(documentId);

    return { disableList };
  },
  methods: {
    openDocumentVersionsList() {
      if (this.disableList) return;
      navigatePanel({
        to: `document_versions-${this.documentId}`,
        panelIndex: this.panelIndex,
        state: { showHidden: this.isHiddenVersion || undefined },
      });
    },
    closePanel() {
      closePanel(this.panelIndex);
    },
    duplicatePanel(event: PointerEvent) {
      if (event.target instanceof HTMLElement) {
        event.target?.blur();
      }
      duplicatePanel(this.panelIndex);
    },
  },
});
</script>
