import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

import { BooksService } from '@/js/api';
import { useDocumentsStore } from '@/js/stores/documents';

export default function useFetchArchives(fetchOnMount?: boolean) {
  const documentsStore = useDocumentsStore();
  const { fetchArchivedDocumentsState, archivedDocuments } =
    storeToRefs(documentsStore);

  const fetchArchivedDocuments = async () => {
    if (fetchArchivedDocumentsState.value !== 'not-started') return;
    fetchArchivedDocumentsState.value = 'fetching';
    try {
      const response = await BooksService.documentArchiveList({});
      archivedDocuments.value = new Map(response.map((d) => [d.id, d]));
      fetchArchivedDocumentsState.value = 'fetched';
      /* c8 ignore next 2 */
    } catch {
      // swallow error
    }
  };
  if (fetchOnMount) {
    onMounted(fetchArchivedDocuments);
  }
  return {
    fetchArchivedDocuments,
  };
}
