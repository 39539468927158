<template>
  <input
    v-if="isEditing"
    ref="input"
    :value="title"
    class="list-item-row item-title"
    required
    :readonly="!connected"
    aria-required="true"
    @change="editTitle"
    @focus="input?.select()"
    @blur="$emit('done-editing')"
    @keyup.enter="input?.blur()"
    @keyup.esc="editTitleCancel"
  />
  <span v-else class="list-item-row item-title">
    {{ title }}
  </span>
  <span v-if="isCurrent && !deletedAt" class="list-item-row item-current">
    <strong>Current Version</strong><span class="details"> | </span>
  </span>
  <span class="list-item-row details can-truncate" data-layout="details">
    <template v-if="deletedAt">Modified </template>
    <UtilityDate :value="modifiedAt" />
    |
    <template v-if="deletedAt">
      Archived <UtilityDate :value="deletedAt" />
    </template>
    <template v-else>
      {{ wordCount }} {{ wordCount === 1 ? 'word' : 'words' }}
    </template>
    <template v-if="isShared">
      | <BooksIcon name="users" size="icon" />
    </template>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, toRefs } from 'vue';

import useTitleInput from '@/js/composables/useTitleInput';
import { useVersionsStore } from '@/js/stores/versions';

export default defineComponent({
  name: 'VersionListItem',
  props: {
    versionId: {
      type: Number as PropType<number | null>,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    modifiedAt: {
      type: String,
      required: true,
    },
    deletedAt: {
      type: String,
      default: undefined,
    },
    wordCount: {
      type: Number,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      required: true,
    },
    connected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['done-editing'],
  setup(props) {
    const { versionId, title, isEditing, connected } = toRefs(props);
    const versionStore = useVersionsStore();

    const isCurrent = computed(() => versionId.value === null);

    const saveTitle = (val: string) => {
      if (!connected.value || versionId.value === null) return;
      versionStore.updateVersion(versionId.value, {
        title: val,
      });
    };

    const { editTitle, editTitleCancel, input } = useTitleInput({
      isEditing,
      title,
      saveTitle,
    });

    return {
      editTitle,
      editTitleCancel,
      input,
      isCurrent,
    };
  },
});
</script>

<style lang="scss" scoped>
.item-title {
  grid-area: title;
}

.item-current {
  grid-area: current;
}

[data-layout='details'] {
  grid-area: details;
}
</style>
