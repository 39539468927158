<template>
  <PanelDocumentDetail
    v-if="document"
    :panel-index="panelIndex"
    :document="document"
    :text="document.text"
    :modified-at="document.text_modified_at"
    :word-count="document.word_count"
  />
  <UtilityLoading v-else-if="fetchArchivedDocumentsState !== 'fetched'" />
  <PanelNotFound v-else :panel-index="panelIndex" />
</template>

<script lang="ts">
import { toNumber } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, watch } from 'vue';

import PanelDocumentDetail from '@/js/components/panels/document-detail.vue';
import PanelNotFound from '@/js/components/panels/not-found.vue';
import useFetchArchives from '@/js/composables/useFetchArchives';
import { useDocumentsStore } from '@/js/stores/documents';

export default defineComponent({
  name: 'DocumentPanelView',
  components: {
    PanelDocumentDetail,
    PanelNotFound,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const documentsStore = useDocumentsStore();
    const docId = computed(() => {
      const id = toNumber(props.id);
      return isNaN(id) ? null : id;
    });
    const document = computed(() =>
      docId.value ? documentsStore.documentOrArchiveById(docId.value) : null,
    );
    const { fetchArchivedDocumentsState } = storeToRefs(documentsStore);

    const { fetchArchivedDocuments } = useFetchArchives(false);
    watch(
      document,
      async () => {
        if (!document.value) {
          await fetchArchivedDocuments();
        }
      },
      { immediate: true },
    );

    return {
      fetchArchivedDocumentsState,
      document,
    };
  },
});
</script>
