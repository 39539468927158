import { acceptHMRUpdate, defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';

import type { AlertType } from '@/js/utils/constants';

export interface Alert {
  id: string;
  type: AlertType;
  message: string;
  safe?: boolean;
  dismiss?: boolean;
  actionLabel?: string;
  actionLink?: string;
}

export interface AlertsState {
  alerts: Map<Alert['id'], Alert>;
}

export const useAlertsStore = defineStore('alerts', {
  state: (): AlertsState => ({ alerts: new Map() }),

  actions: {
    addAlert({
      type,
      message,
      safe,
      dismiss,
      actionLabel,
      actionLink,
    }: Omit<Alert, 'id'>) {
      const id = uuid();
      const alert: Alert = { id, type, message, safe, actionLabel, actionLink };
      this.alerts.set(id, alert);
      if (dismiss) {
        setTimeout(() => {
          this.removeAlert(id);
        }, 5000);
      }
      return id;
    },

    removeAlert(id: string) {
      this.alerts.delete(id);
    },
  },
});

/* c8 ignore next 3 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAlertsStore, import.meta.hot));
}
