<template>
  <PanelHeader :panel-index="panelIndex">
    <template #menu>
      <PanelHeaderMenu
        :panel-index="panelIndex"
        :document-id="document.id"
        :is-hidden-version="isHidden"
        :is-archived-document="isArchived"
      >
        <hr />
        <template v-if="isArchived">
          <UtilityMenuButton :disabled="!connected" @click="printDocument">
            Print
          </UtilityMenuButton>
          <hr />
          <UtilityMenuButton
            v-if="isCurrent"
            :disabled="!connected"
            @click="restoreDocument(document.id)"
          >
            Restore from Archives
          </UtilityMenuButton>
        </template>
        <template v-else>
          <template v-if="isCurrent">
            <UtilityMenuButton
              :disabled="!connected"
              @click="setEditingDocument(document.id)"
            >
              Rename
            </UtilityMenuButton>
            <UtilityMenuButton :disabled="!connected" @click="saveVersion">
              Save a Version
            </UtilityMenuButton>
          </template>
          <template v-else>
            <UtilityMenuButton :disabled="!connected" @click="renameVersion">
              Rename Version
            </UtilityMenuButton>
            <UtilityMenuButton :disabled="!connected" @click="restoreVersion">
              Make This Version Current
            </UtilityMenuButton>
          </template>
          <UtilityMenuButton :disabled="!connected" @click="saveAsDocument">
            Save as New Document
          </UtilityMenuButton>
          <UtilityMenuButton :disabled="!connected" @click="printDocument">
            Print
          </UtilityMenuButton>
          <UtilityMenuButton
            :disabled="!connected"
            @click="shareModalOpen = true"
          >
            {{ isCurrent ? 'Share' : 'Share Version' }}
          </UtilityMenuButton>
          <hr />
          <UtilityMenuButton v-if="compareText" @click="exitComparison">
            Exit Comparison View
          </UtilityMenuButton>
          <UtilityMenuButton
            v-else-if="isCurrent"
            :disabled="disableList"
            @click="compareModalOpen = true"
          >
            Compare to Saved Version
          </UtilityMenuButton>
          <UtilityMenuButton
            v-else
            :disabled="!connected"
            @click="compareToCurrent"
          >
            Compare to Current
          </UtilityMenuButton>
          <hr />
          <UtilityMenuButton
            v-if="isCurrent"
            :disabled="!connected"
            @click="archiveDocModalOpen = true"
          >
            Archive
          </UtilityMenuButton>
          <UtilityMenuButton
            v-else-if="isHidden"
            :disabled="!connected || archivingVersion"
            @click="unhideVersion"
          >
            Unhide Version
          </UtilityMenuButton>
          <UtilityMenuButton
            v-else
            :disabled="!connected || archivingVersion"
            @click="hideVersion"
          >
            Hide Version
          </UtilityMenuButton>
        </template>
      </PanelHeaderMenu>
    </template>

    <DocumentTitle :title="title" />
    <DocumentBreadcrumb
      :panel-index="panelIndex"
      :document-id="document.id"
      :title="document.title"
      :modified-at="modifiedAt"
      :archived-at="document.deleted || undefined"
      :word-count="wordCount"
      :is-current="isCurrent"
      :is-hidden-version="isHidden"
      :is-archived-document="isArchived"
      :is-shared="
        isCurrent ? document.public_id !== null : versionPublicId !== null
      "
      @share="shareModalOpen = true"
    />
    <UtilitySaveIndicator v-if="isCurrent" :state="savingIndicator" />
  </PanelHeader>

  <div v-if="compareText" data-panel-mode="comparing">
    <div class="panel-mode-content">
      <span class="panel-mode-title">
        <em>Comparing to</em> <strong>Current Version</strong>
      </span>
      <button
        data-btn
        class="compare-exit"
        type="button"
        @click="exitComparison"
      >
        Exit
      </button>
    </div>
  </div>

  <div v-else-if="isHidden" data-panel-mode="see-hidden">
    <div class="panel-mode-content">
      <span class="panel-mode-title"
        ><BooksIcon name="lock" class="static-icon" /><strong
          >Hidden</strong
        ></span
      >
      <button
        :disabled="!connected || archivingVersion"
        data-btn
        class="unhide"
        type="button"
        @click="unhideVersion"
      >
        Unhide
      </button>
    </div>
  </div>

  <div v-else-if="isArchived" data-panel-mode="archive">
    <div class="panel-mode-content">
      <span class="panel-mode-title"
        ><BooksIcon name="lock" class="static-icon" /><strong
          >Archived</strong
        ></span
      >
      <UtilityLink :href="`/write/archive`" @click.prevent="backToArchive"
        >Back to Archive</UtilityLink
      >
      <button
        :disabled="!connected || archivingVersion"
        data-btn
        class="restore"
        type="button"
        @click="restoreDocument(document.id)"
      >
        Restore
      </button>
    </div>
  </div>

  <div v-else-if="!isCurrent" data-panel-mode="readonly">
    <div class="panel-mode-content">
      <span class="panel-mode-title"
        ><BooksIcon name="lock" class="static-icon" /><strong
          >Read Only</strong
        ></span
      >
      <button
        :disabled="!connected"
        type="button"
        data-btn
        @click="restoreVersion"
      >
        Make Current
      </button>
      <button
        :disabled="!connected"
        type="button"
        data-btn
        @click="compareToCurrent"
      >
        Compare to Current
      </button>
    </div>
  </div>

  <div
    ref="container"
    :class="{ 'content-wrapper': true, 'diff-container': compareText !== null }"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="compareText"
      data-panel="scroll-content"
      data-testid="diff"
      class="books-canvas"
      v-html="diff"
    />
    <template v-else-if="isCurrent && !isArchived">
      <QuillEditor
        v-if="container"
        ref="editor"
        v-model:content="editorContent"
        theme="snow"
        :modules="{
          name: 'Syntax',
          module: Syntax,
          options: {
            highlight: (text: string) => hljs.highlightAuto(text).value,
          },
        }"
        :toolbar="[
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'code-block', 'clean'],
        ]"
        content-type="html"
        :options="{
          formats: ALLOWED_FORMATS,
          bounds: container,
          scrollingContainer: container,
        }"
        class="books-canvas"
        placeholder="Type your text"
        data-panel="scroll-content flex"
        data-testid="editor"
        @focusin="editorHasFocus = true"
        @focusout="blurEditorFocus"
        @vue:mounted="editor?.focus()"
      />
    </template>
    <div
      v-else
      data-panel="scroll-content"
      class="books-canvas"
      v-html="editorContent"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>

  <VersionCompareModal
    v-if="compareModalOpen"
    :panel-index="panelIndex"
    :document="document"
    :connected="connected"
    @close="compareModalOpen = false"
  />

  <DocumentArchiveModal
    v-if="archiveDocModalOpen"
    :document-id="document.id"
    :document-title="document.title"
    :document-is-shared="!!document.public_id"
    :connected="connected"
    @close="archiveDocModalOpen = false"
  />

  <DocumentShareModal
    v-if="shareModalOpen"
    :version-id="isCurrent ? document.id : versionId!"
    :is-current="isCurrent"
    :public-id="isCurrent ? document.public_id : versionPublicId"
    :connected="connected"
    @close="shareModalOpen = false"
  />
</template>

<script lang="ts">
import * as Quill from '@vueup/vue-quill';
import hljs from 'highlight.js/lib/common';
import { debounce } from 'lodash';
import { mapActions } from 'pinia';
import {
  computed,
  defineComponent,
  onUnmounted,
  type PropType,
  ref,
  toRefs,
  watch,
} from 'vue';

import type { DocumentRead } from '@/js/api';
import DocumentArchiveModal from '@/js/components/document/archive-modal.vue';
import DocumentBreadcrumb from '@/js/components/document/breadcrumb.vue';
import DocumentShareModal from '@/js/components/document/share-modal.vue';
import DocumentTitle from '@/js/components/document/title.vue';
import PanelHeader from '@/js/components/panels/header.vue';
import PanelHeaderMenu from '@/js/components/panels/header-menu.vue';
import VersionCompareModal from '@/js/components/version/compare-modal.vue';
import useDisableVersionsList from '@/js/composables/useDisableVersionsList';
import { navigatePanel } from '@/js/router/panels';
import { useDocumentsStore } from '@/js/stores/documents';
import { useSocketStore } from '@/js/stores/socket';
import { useVersionsStore } from '@/js/stores/versions';
import {
  ALLOWED_FORMATS,
  SAVE_STATE,
  type SaveState,
} from '@/js/utils/constants';
import { removeCursorSpans } from '@/js/utils/escape';
import htmldiff from '@/js/utils/htmldiff';
import printContent from '@/js/utils/printContent';

const Syntax = Quill.Quill.import('modules/syntax');

export default defineComponent({
  name: 'PanelDocumentDetail',
  components: {
    DocumentArchiveModal,
    DocumentBreadcrumb,
    DocumentShareModal,
    DocumentTitle,
    PanelHeader,
    PanelHeaderMenu,
    QuillEditor: Quill.QuillEditor,
    VersionCompareModal,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    document: {
      type: Object as PropType<DocumentRead>,
      required: true,
    },
    versionId: {
      type: Number as PropType<number | null>,
      default: null,
    },
    versionPublicId: {
      type: String as PropType<string | null>,
      default: null,
    },
    versionTitle: {
      type: String,
      default: '',
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    modifiedAt: {
      type: String,
      required: true,
    },
    wordCount: {
      type: Number,
      required: true,
    },
    compareText: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  setup(props) {
    const { document, versionId, text, compareText } = toRefs(props);
    const socketStore = useSocketStore();
    const documentsStore = useDocumentsStore();

    const editor = ref<InstanceType<typeof Quill.QuillEditor> | null>(null);
    const container = ref<HTMLDivElement | null>(null);
    const savingIndicator = ref<SaveState>(SAVE_STATE.INACTIVE);
    const saving = ref(false);
    const compareModalOpen = ref(false);
    const archivingVersion = ref(false);
    const shareModalOpen = ref(false);
    const archiveDocModalOpen = ref(false);
    const editorHasFocus = ref(false);

    const connected = computed(() => socketStore.connected);
    const disableList = useDisableVersionsList(document.value.id);
    const draft = computed(() =>
      versionId.value
        ? null
        : documentsStore.draftByDocument(document.value.id),
    );
    const isCurrent = computed(() => versionId.value === null);
    const isArchived = computed(() => document.value.deleted !== null);
    const hasChanges = computed(
      () => removeCursorSpans(editorContent.value) !== text.value,
    );

    const setDraft = (text: string) =>
      documentsStore.setDraft({ text, id: document.value.id });
    const updateText = async (text: string) =>
      documentsStore.updateDocument(document.value.id, { text });
    const setDraftDebounced = debounce(setDraft, 250);
    const updateTextDebounced = debounce(async (text: string) => {
      if (!hasChanges.value) return;
      saving.value = true;
      savingIndicator.value = SAVE_STATE.SAVING;
      if (await updateText(text)) {
        savingIndicator.value = SAVE_STATE.SAVED;
      } else {
        savingIndicator.value = SAVE_STATE.INACTIVE;
      }
    }, 5000);

    const editorContent = computed({
      get: () => draft.value || text.value,
      set: (val) => {
        if (
          editorHasFocus.value &&
          removeCursorSpans(val) !== removeCursorSpans(editorContent.value)
        ) {
          // store local draft
          setDraftDebounced(val);
          // save to server
          updateTextDebounced(val);
        }
      },
    });
    const diff = computed(() =>
      compareText.value !== null
        ? htmldiff(compareText.value, editorContent.value)
        : null,
    );

    const warnBeforeClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return (event.returnValue = '');
    };
    const hasWarnBeforeClose = ref(false);

    const title = computed(() =>
      isCurrent.value ? document.value.title : props.versionTitle,
    );

    watch(hasChanges, () => {
      if (
        hasChanges.value &&
        editorHasFocus.value &&
        !hasWarnBeforeClose.value
      ) {
        addEventListener('beforeunload', warnBeforeClose, { capture: true });
        hasWarnBeforeClose.value = true;
      } else if (hasWarnBeforeClose.value) {
        removeEventListener('beforeunload', warnBeforeClose, {
          capture: true,
        });
        hasWarnBeforeClose.value = false;
      }
    });

    /* c8 ignore start */
    onUnmounted(() => {
      if (hasWarnBeforeClose.value) {
        removeEventListener('beforeunload', warnBeforeClose, {
          capture: true,
        });
      }
    });
    /* c8 ignore stop */

    return {
      ALLOWED_FORMATS,
      Syntax,
      archiveDocModalOpen,
      archivingVersion,
      compareModalOpen,
      connected,
      container,
      diff,
      disableList,
      editor,
      editorContent,
      editorHasFocus,
      hljs,
      isArchived,
      isCurrent,
      saving,
      savingIndicator,
      setDraftDebounced,
      setEditingDocument: documentsStore.setEditingDocument,
      shareModalOpen,
      title,
      updateTextDebounced,
    };
  },
  methods: {
    ...mapActions(useDocumentsStore, [
      'createDocument',
      'restoreFromVersion',
      'restoreDocument',
    ]),
    ...mapActions(useVersionsStore, [
      'archiveVersion',
      'createVersion',
      'unarchiveVersion',
    ]),
    blurEditorFocus(event: FocusEvent) {
      // Actions such as clipboard paste may trigger a `focusout` on a portion
      // of the editor, but the editor maintains focus. This verifies that the
      // `relatedTarget` (which is the next element to receive focus) is outside
      // of the text editor.
      /* c8 ignore start */
      if (
        event.relatedTarget instanceof Node &&
        this.editor?.getEditor().contains(event.relatedTarget)
      ) {
        return;
      }
      /* c8 ignore stop */

      this.editorHasFocus = false;
      this.setDraftDebounced.flush();
      this.updateTextDebounced.flush();
    },
    exitComparison() {
      navigatePanel({
        to: `version-${this.versionId}`,
        panelIndex: this.panelIndex,
      });
    },
    compareToCurrent() {
      navigatePanel({
        to: `compare-${this.document.id}-${this.versionId}`,
        panelIndex: this.panelIndex,
      });
    },
    async saveVersion() {
      const version = await this.createVersion({
        document: this.document.id,
        title: 'Untitled',
      });
      if (version) {
        navigatePanel({
          to: `document_versions-${this.document.id}`,
          panelIndex: this.panelIndex,
          state: { editingVersionId: version.id },
        });
      }
    },
    renameVersion() {
      navigatePanel({
        to: `document_versions-${this.document.id}`,
        panelIndex: this.panelIndex,
        state: { editingVersionId: this.versionId },
      });
    },
    async restoreVersion() {
      const doc = await this.restoreFromVersion(this.document.id, {
        text: this.text,
      });
      /* c8 ignore next */
      if (!doc) return;
      navigatePanel({
        to: `document-${this.document.id}`,
        panelIndex: this.panelIndex,
      });
    },
    async saveAsDocument() {
      const doc = await this.createDocument({
        parent_id: this.document.id,
        title: `Copy of ${this.versionTitle || this.document.title}`,
        text: this.text,
      });
      /* c8 ignore next */
      if (!doc) return;
      navigatePanel({
        to: `document-${doc.id}`,
        panelIndex: this.panelIndex,
      });
    },
    async hideVersion() {
      if (this.isCurrent) return;
      this.archivingVersion = true;
      await this.archiveVersion(this.versionId!);
      this.archivingVersion = false;
    },
    async unhideVersion() {
      if (this.isCurrent) return;
      this.archivingVersion = true;
      await this.unarchiveVersion(this.versionId!);
      this.archivingVersion = false;
    },
    backToArchive() {
      navigatePanel({
        to: 'archive',
        panelIndex: this.panelIndex,
      });
    },
    printDocument(event: PointerEvent) {
      if (event.target instanceof HTMLElement) {
        event.target?.blur();
      }
      const title = this.title;
      /* c8 ignore next 4*/
      const content =
        (this.compareText
          ? `<div class="diff-container">${this.diff}</div>`
          : this.editorContent) || '';

      printContent(content, title);
    },
  },
});
</script>

<style lang="css">
@import url(@vueup/vue-quill/dist/vue-quill.snow.css) layer(third-party);
</style>
