<template>
  <div data-layout-list>
    <disclosure-inline ref="disclosure" :data-open="startExpanded || null">
      <div class="current list-item" data-listitem="document">
        <IconButton
          tag="div"
          icon-name="chevron"
          data-btn="icon view-versions"
          hidden-text="view document versions"
          data-insert-trigger
          @click="expanded = !expanded"
        />
        <UtilityLink
          :href="`/write/document-${document.id}`"
          data-meta="docinfo"
          data-link="block"
          @click.prevent="openDocument"
        >
          <VersionListItem
            :title="document.title"
            :modified-at="document.text_modified_at"
            :word-count="document.word_count"
            :is-shared="document.public_id !== null"
            :connected="connected"
          />
        </UtilityLink>
      </div>
      <disclosure-content>
        <UtilityLoading v-if="fetching" />
        <template v-else>
          <div class="list-header">
            <h3 class="list-title">Saved Versions</h3>
            <VersionToggleHidden
              v-if="hasHiddenVersions"
              :show-hidden="showHidden"
              @toggle="showHidden = !showHidden"
            />
          </div>
          <ul
            v-if="versions.length"
            class="list-body"
            data-list="plain versions"
          >
            <li
              v-for="version in visibleVersions"
              :key="version.id"
              class="list-item"
              data-listitem="version"
              :data-hidden="version.deleted !== null || null"
            >
              <component
                :is="editingVersionId === version.id ? 'div' : 'a'"
                data-meta="docinfo"
                data-link="block"
                :href="
                  editingVersionId === version.id
                    ? undefined
                    : `/write/version-${version.id}`
                "
                @click.prevent="
                  editingVersionId !== version.id && openVersion(version.id)
                "
              >
                <VersionListItem
                  :version-id="version.id"
                  :title="version.title || document.title"
                  :modified-at="version.text_modified_at"
                  :word-count="version.word_count"
                  :is-editing="editingVersionId === version.id"
                  :is-shared="version.public_id !== null"
                  :connected="connected"
                  @done-editing="$emit('editing-version-id', null)"
                />
              </component>
              <UtilityMenu
                v-if="!hideActions && editingVersionId !== version.id"
              >
                <UtilityMenuButton
                  :disabled="!connected"
                  @click="$emit('editing-version-id', version.id)"
                >
                  Rename Version
                </UtilityMenuButton>
                <UtilityMenuButton
                  :disabled="!connected"
                  @click="restoreVersion(version.text)"
                >
                  Make This Version Current
                </UtilityMenuButton>
                <UtilityMenuButton
                  :disabled="!connected"
                  @click="saveAsDocument(version)"
                >
                  Save as New Document
                </UtilityMenuButton>
                <UtilityMenuButton
                  :disabled="!connected"
                  @click="sharingVersionId = version.id"
                >
                  Share Version
                </UtilityMenuButton>
                <hr />
                <UtilityMenuButton
                  :disabled="!connected"
                  @click="compareToCurrent(version)"
                >
                  Compare to Current
                </UtilityMenuButton>
                <hr />
                <UtilityMenuButton
                  v-if="version.deleted === null"
                  :disabled="!connected || archiving"
                  @click="hideVersion(version)"
                >
                  Hide Version
                </UtilityMenuButton>
                <UtilityMenuButton
                  v-else
                  :disabled="!connected || archiving"
                  @click="unhideVersion(version)"
                >
                  Unhide Version
                </UtilityMenuButton>
              </UtilityMenu>
            </li>
          </ul>
          <span v-else data-message="empty">
            There are no older saved versions of this document yet.
          </span>
        </template>
      </disclosure-content>
    </disclosure-inline>
  </div>

  <DocumentShareModal
    v-if="sharingVersion"
    :version-id="sharingVersion.id"
    :is-current="false"
    :public-id="sharingVersion.public_id"
    :connected="connected"
    @close="sharingVersionId = null"
  />
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import {
  computed,
  defineComponent,
  onMounted,
  type PropType,
  ref,
  toRefs,
} from 'vue';

import type { DocumentRead, VersionRead } from '@/js/api';
import DocumentShareModal from '@/js/components/document/share-modal.vue';
import VersionListItem from '@/js/components/version/list-item.vue';
import VersionToggleHidden from '@/js/components/version/toggle-hidden.vue';
import useDisclosure from '@/js/composables/useDisclosure';
import useFetchVersions from '@/js/composables/useFetchVersions';
import useHiddenVersions from '@/js/composables/useHiddenVersions';
import { navigatePanel } from '@/js/router/panels';
import { useDocumentsStore } from '@/js/stores/documents';
import { useSocketStore } from '@/js/stores/socket';
import { useVersionsStore } from '@/js/stores/versions';

export default defineComponent({
  name: 'VersionList',
  components: {
    DocumentShareModal,
    VersionListItem,
    VersionToggleHidden,
  },
  props: {
    panelIndex: {
      type: Number,
      default: undefined,
    },
    document: {
      type: Object as PropType<DocumentRead>,
      required: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    startExpanded: {
      type: Boolean,
      default: false,
    },
    editingVersionId: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  emits: {
    'editing-version-id': (_value: number | null) => true,
  },
  setup(props) {
    const { document, startExpanded } = toRefs(props);
    const { disclosure } = useDisclosure();
    const socketStore = useSocketStore();
    const versionsStore = useVersionsStore();
    const connected = computed(() => socketStore.connected);
    const archiving = ref<boolean>(false);
    const expanded = ref<boolean>(startExpanded.value);

    const docId = computed(() => (expanded.value ? document.value.id : null));
    const { fetching, versions } = useFetchVersions(docId);
    const { hasHiddenVersions, showHidden, visibleVersions } =
      useHiddenVersions(versions);

    const sharingVersionId = ref<number | null>(null);
    const sharingVersion = computed(() =>
      sharingVersionId.value === null
        ? null
        : versionsStore.versionById(sharingVersionId.value),
    );

    onMounted(() => {
      if (history.state?.showHidden) {
        history.replaceState({ ...history.state, showHidden: undefined }, '');
        showHidden.value = true;
      }
    });

    return {
      archiving,
      connected,
      disclosure,
      expanded,
      fetching,
      hasHiddenVersions,
      sharingVersion,
      sharingVersionId,
      showHidden,
      versions,
      visibleVersions,
    };
  },
  methods: {
    ...mapActions(useDocumentsStore, ['restoreFromVersion', 'createDocument']),
    ...mapActions(useVersionsStore, ['archiveVersion', 'unarchiveVersion']),
    openDocument() {
      navigatePanel({
        to: `document-${this.document.id}`,
        panelIndex: this.panelIndex,
      });
    },
    openVersion(id: number) {
      navigatePanel({ to: `version-${id}`, panelIndex: this.panelIndex });
    },
    compareToCurrent(version: VersionRead) {
      navigatePanel({
        to: `compare-${this.document.id}-${version.id}`,
        panelIndex: this.panelIndex,
      });
    },
    async restoreVersion(text: string) {
      const doc = await this.restoreFromVersion(this.document.id, {
        text,
      });
      /* c8 ignore next */
      if (!doc) return;
      this.openDocument();
    },
    async hideVersion(version: VersionRead) {
      this.archiving = true;
      await this.archiveVersion(version.id);
      this.archiving = false;
    },
    async unhideVersion(version: VersionRead) {
      this.archiving = true;
      await this.unarchiveVersion(version.id);
      this.archiving = false;
    },
    async saveAsDocument(version: VersionRead) {
      const doc = await this.createDocument({
        parent_id: this.document.id,
        title: `Copy of ${version.title || this.document.title}`,
        text: version.text,
      });
      /* c8 ignore next */
      if (!doc) return;
      navigatePanel({
        to: `document-${doc.id}`,
        panelIndex: this.panelIndex,
      });
    },
  },
});
</script>
