import { orderBy } from 'lodash';
import { computed, type ComputedRef, onMounted, watch } from 'vue';

import { BooksService } from '@/js/api';
import { useDocumentsStore } from '@/js/stores/documents';
import { useVersionsStore } from '@/js/stores/versions';

export default function useFetchVersions(docId: ComputedRef<number | null>) {
  const documentsStore = useDocumentsStore();
  const versionsStore = useVersionsStore();
  const hasFetched = computed(() =>
    docId.value
      ? versionsStore.hasFetchedVersionsForDocument(docId.value)
      : false,
  );
  const fetching = computed(() =>
    docId.value ? documentsStore.fetchingVersions(docId.value) : false,
  );
  const orderedVersions = computed(() => {
    if (!docId.value) {
      return [];
    }
    const versions = versionsStore.versionsByDoc(docId.value);
    return orderBy(
      versions,
      ['text_modified_at', 'created_at'],
      ['desc', 'desc'],
    );
  });

  const fetchVersions = async () => {
    if (docId.value !== null && !hasFetched.value && !fetching.value) {
      documentsStore.fetching.add(docId.value);
      try {
        const response = await BooksService.versionList({
          document: docId.value,
        });
        versionsStore.setVersions(response);
        versionsStore.fetchedDocuments.add(docId.value);
        /* c8 ignore next 2 */
      } catch {
        // swallow error
      } finally {
        documentsStore.fetching.delete(docId.value);
      }
    }
  };

  onMounted(fetchVersions);
  watch(docId, fetchVersions);

  return {
    versions: orderedVersions,
    fetching,
  };
}
