import {
  compact,
  type Dictionary,
  includes,
  isNaN,
  isNumber,
  map,
  toNumber,
  zipObject,
  zipWith,
} from 'lodash';
import { v4 as uuid } from 'uuid';
import type { HistoryState } from 'vue-router';

import getRouter from '@/js/router';
import { useVersionsStore } from '@/js/stores/versions';
import { SCROLL_LEFT } from '@/js/utils/constants';

export type PanelType = 'document' | 'version' | 'account' | 'archive';
export type PanelComponent =
  | 'DocumentPanelView'
  | 'MyAccountPanelView'
  | 'PanelNotFound'
  | 'VersionComparePanelView'
  | 'VersionDetailPanelView'
  | 'VersionListPanelView'
  | 'ArchivePanelView';

interface PanelDef {
  component: PanelComponent;
  props: string[];
  type?: PanelType;
}

export interface Panel {
  component: PanelComponent;
  props?: Dictionary<string>;
  type?: PanelType;
  id: string;
  route: string;
}

export const PANELS: Dictionary<PanelDef | undefined> = {
  document: {
    component: 'DocumentPanelView',
    props: ['id'],
    type: 'document' as PanelType,
  },
  document_versions: {
    component: 'VersionListPanelView',
    props: ['id'],
    type: 'document' as PanelType,
  },
  version: {
    component: 'VersionDetailPanelView',
    props: ['id'],
    type: 'version' as PanelType,
  },
  compare: {
    component: 'VersionComparePanelView',
    props: ['documentId', 'versionId'],
    type: 'document' as PanelType,
  },
  account: {
    component: 'MyAccountPanelView',
    props: [],
    type: 'account' as PanelType,
  },
  archive: {
    component: 'ArchivePanelView',
    props: [],
    type: 'archive' as PanelType,
  },
};

const splitPanelsRoute = (route: string) => route.split(/\.\.\.|\.\./);

const getPanelFromRoute = (route: string): Omit<Panel, 'id'> => {
  const bits = route.split('-');
  if (bits.length) {
    const panelDef = PANELS[bits[0]];
    if (panelDef && bits.length === panelDef.props.length + 1) {
      return {
        component: panelDef.component,
        props: zipObject(panelDef.props, bits.slice(1)),
        type: panelDef.type,
        route,
      };
    }
  }

  // No known panel found
  return { component: 'PanelNotFound', route };
};

const getPanelIds = (): string[] => history.state?.panelIds?.slice() ?? [];

// Get list of panel objects from the route string
export const getPanelsFromRoute = (route: string): Panel[] => {
  if (!route) {
    return [];
  }
  const panels = compact(splitPanelsRoute(route)).map(getPanelFromRoute);
  let ids = getPanelIds();
  // If we (somehow) don't have the right number of ids, just re-generate them
  if (panels.length !== ids.length) {
    ids = panels.map(() => uuid());
    history.replaceState({ ...history.state, panelIds: ids }, '');
  }
  return zipWith(panels, ids, (panel, id) => ({ ...panel, id }));
};

// Get the primary document or version id from any panel type
export const getPanelDocOrVersionId = (panel: Panel) => {
  let id: string | undefined;
  switch (panel.type) {
    case 'document':
      id = panel.props?.id ?? panel.props?.documentId;
      break;
    case 'version':
      id = panel.props?.id;
      break;
  }
  const panelId = toNumber(id);
  if (!isNaN(panelId)) {
    return panelId;
  }
  return null;
};

// Get the primary version id from any panel type
export const getPanelVersionId = (panel: Panel) => {
  let id: string | undefined;
  switch (panel.type) {
    case 'document':
      id = panel.props?.versionId;
      break;
    case 'version':
      id = panel.props?.id;
      break;
  }
  const panelId = toNumber(id);
  if (!isNaN(panelId)) {
    return panelId;
  }
  return null;
};

export const navigatePanel = ({
  to,
  panelIndex,
  openAfter,
  state,
}: {
  to: string;
  panelIndex?: number;
  openAfter?: boolean;
  state?: HistoryState;
}) => {
  const router = getRouter();
  const { currentRoute } = router;
  if (!state) {
    state = {};
  }
  if (
    currentRoute.value.name !== 'Write' ||
    !currentRoute.value.params.panels
  ) {
    // Navigate to new panel
    return router.push({
      name: 'Write',
      params: { panels: to },
      state: { ...state, panelIds: [uuid()] },
    });
  }
  const ids = getPanelIds();
  if (!isNumber(panelIndex)) {
    // Open new panel before existing panels
    return router.push({
      name: 'Write',
      params: {
        panels: `${to}..${currentRoute.value.params.panels}`,
      },
      state: {
        ...state,
        panelScroll: SCROLL_LEFT,
        panelIds: [uuid(), ...ids],
      },
    });
  }
  const panels = splitPanelsRoute(currentRoute.value.params.panels as string);
  if (openAfter) {
    // Open new panel after specified index
    panels.splice(panelIndex + 1, 0, to);
    ids.splice(panelIndex + 1, 0, uuid());
    return router.push({
      name: 'Write',
      params: { panels: panels.join('..') },
      state: { ...state, panelIds: ids },
    });
  }
  // Replace existing panel with new panel
  panels.splice(panelIndex, 1, to);
  ids.splice(panelIndex, 1, uuid());
  return router.push({
    name: 'Write',
    params: { panels: panels.join('..') },
    state: { ...state, panelIds: ids },
  });
};

export const closePanel = (panelIndex: number) => {
  const router = getRouter();
  const { currentRoute } = router;
  if (
    currentRoute.value.name === 'Write' &&
    currentRoute.value.params.panels &&
    isNumber(panelIndex)
  ) {
    // Remove existing panel
    const panels = splitPanelsRoute(currentRoute.value.params.panels as string);
    const ids = getPanelIds();
    panels.splice(panelIndex, 1);
    ids.splice(panelIndex, 1);
    router.push({
      name: 'Write',
      params: { panels: panels.join('..') },
      state: { panelIds: ids },
    });
  }
};

export const closePanels = (panelIndexes: number[]) => {
  const router = getRouter();
  const { currentRoute } = router;
  if (
    currentRoute.value.name === 'Write' &&
    currentRoute.value.params.panels &&
    panelIndexes.length
  ) {
    // Remove existing panels
    const panels = splitPanelsRoute(currentRoute.value.params.panels as string);
    const ids = getPanelIds();
    panelIndexes.reverse();
    for (const panelIndex of panelIndexes) {
      panels.splice(panelIndex, 1);
      ids.splice(panelIndex, 1);
    }
    router.push({
      name: 'Write',
      params: { panels: panels.join('..') },
      state: { panelIds: ids },
    });
  }
};

export const duplicatePanel = (panelIndex: number) => {
  const router = getRouter();
  const { currentRoute } = router;
  if (
    currentRoute.value.name === 'Write' &&
    currentRoute.value.params.panels &&
    isNumber(panelIndex)
  ) {
    // Duplicate existing panel
    const panels = splitPanelsRoute(currentRoute.value.params.panels as string);
    const ids = getPanelIds();
    panels.splice(panelIndex + 1, 0, panels[panelIndex]);
    ids.splice(panelIndex + 1, 0, uuid());
    return router.push({
      name: 'Write',
      params: { panels: panels.join('..') },
      state: { panelIds: ids },
    });
  }
};

// Close all open panels for a single document
export const closeDocumentPanels = (id: number) => {
  const router = getRouter();
  const { currentRoute } = router;
  const route = currentRoute.value.params.panels as string | undefined;
  if (currentRoute.value.name === 'Write' && route) {
    const toRemove: number[] = [];
    const panelObjects = getPanelsFromRoute(route);
    const versionIds = map(useVersionsStore().versionsByDoc(id), 'id');
    panelObjects.forEach((panel, index) => {
      const docOrVersionId = getPanelDocOrVersionId(panel);
      if (panel.type && docOrVersionId !== null) {
        let match = false;
        switch (panel.type) {
          case 'document':
            match = docOrVersionId === id;
            break;
          case 'version': {
            match = includes(versionIds, docOrVersionId);
            break;
          }
        }
        if (match) {
          toRemove.push(index);
        }
      }
    });
    closePanels(toRemove);
  }
};

// Close all open panels for a single version
export const closeVersionPanels = (id: number) => {
  const router = getRouter();
  const { currentRoute } = router;
  const route = currentRoute.value.params.panels as string | undefined;
  if (currentRoute.value.name === 'Write' && route) {
    const toRemove: number[] = [];
    const panelObjects = getPanelsFromRoute(route);
    panelObjects.forEach((panel, index) => {
      const versionId = getPanelVersionId(panel);
      if (panel.type && versionId !== null && versionId === id) {
        toRemove.push(index);
      }
    });
    closePanels(toRemove);
  }
};
