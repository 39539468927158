<template>
  <div data-nav="doc-list-nav">
    <div data-header="docs-list" class="list-actions">
      <UserMenu />
      <IconButton
        v-if="connected"
        icon-name="add"
        text="New Document"
        @click="addDocument"
      />
      <p v-else data-status="warning">
        Cannot add or open documents while offline.
      </p>
    </div>

    <nav class="list-body documents-list">
      <ul data-list="plain documents">
        <DocumentListItem
          v-for="document in orderedDocuments"
          :key="document.id"
          :document="document"
          :is-open="documentIsOpen(document.id)"
          :is-editing="editing === document.id"
          :connected="connected"
          @sharing="(docId) => (sharingDocumentId = docId)"
          @archiving="(docId) => (archivingDocumentId = docId)"
        />
      </ul>
      <span v-if="!orderedDocuments.length" data-message="empty">
        You have no documents yet. Add a new document to get started!
      </span>
    </nav>
  </div>

  <DocumentArchiveModal
    v-if="archivingDocument"
    :document-id="archivingDocument.id"
    :document-title="archivingDocument.title"
    :document-is-shared="!!archivingDocument.public_id"
    :connected="connected"
    @close="archivingDocumentId = null"
  />

  <DocumentShareModal
    v-if="sharingDocument"
    :version-id="sharingDocument.id"
    is-current
    :public-id="sharingDocument.public_id"
    :connected="connected"
    @close="sharingDocumentId = null"
  />
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';

import DocumentArchiveModal from '@/js/components/document/archive-modal.vue';
import DocumentListItem from '@/js/components/document/list-item.vue';
import DocumentShareModal from '@/js/components/document/share-modal.vue';
import UserMenu from '@/js/components/navigation/user-menu.vue';
import { useDocumentsStore } from '@/js/stores/documents';
import { useSocketStore } from '@/js/stores/socket';

export default defineComponent({
  name: 'DocumentList',
  components: {
    DocumentArchiveModal,
    DocumentListItem,
    DocumentShareModal,
    UserMenu,
  },
  data() {
    return {
      archivingDocumentId: null as number | null,
      sharingDocumentId: null as number | null,
    };
  },
  computed: {
    ...mapState(useSocketStore, ['connected']),
    ...mapState(useDocumentsStore, [
      'documentById',
      'documentIsOpen',
      'editing',
      'orderedDocuments',
    ]),
    archivingDocument() {
      if (this.archivingDocumentId === null) return null;
      return this.documentById(this.archivingDocumentId);
    },
    sharingDocument() {
      if (this.sharingDocumentId === null) return null;
      return this.documentById(this.sharingDocumentId);
    },
  },
  watch: {
    archivingDocument(doc) {
      // Modal is removed before close event can be called, so clean up and
      // remove archivingDocumentId when the document is archived and removed
      // from the store. Otherwise, the archive modal is re-opened when the
      // document is unarchived and returned to the store.
      if (doc === null) {
        this.archivingDocumentId = null;
      }
    },
  },
  methods: {
    ...mapActions(useDocumentsStore, ['addDocument']),
  },
});
</script>
