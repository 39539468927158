/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentCreate } from '../models/DocumentCreate';
import type { DocumentRead } from '../models/DocumentRead';
import type { DocumentReadPublic } from '../models/DocumentReadPublic';
import type { DocumentUpdate } from '../models/DocumentUpdate';
import type { ShareSettingsUpdate } from '../models/ShareSettingsUpdate';
import type { VersionCreate } from '../models/VersionCreate';
import type { VersionRead } from '../models/VersionRead';
import type { VersionReadPublic } from '../models/VersionReadPublic';
import type { VersionUpdate } from '../models/VersionUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OddbooksService {
    /**
     * Document List
     * @returns DocumentRead Successful Response
     * @throws ApiError
     */
    public static documentList({
        skip,
        limit = 100,
    }: {
        skip?: number,
        limit?: number,
    }): CancelablePromise<Array<DocumentRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Document Create
     * @returns DocumentRead Successful Response
     * @throws ApiError
     */
    public static documentCreate({
        requestBody,
    }: {
        requestBody: DocumentCreate,
    }): CancelablePromise<DocumentRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/documents/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Document Archive List
     * @returns DocumentRead Successful Response
     * @throws ApiError
     */
    public static documentArchiveList({
        skip,
        limit = 100,
    }: {
        skip?: number,
        limit?: number,
    }): CancelablePromise<Array<DocumentRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/archive/',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Document Read
     * @returns DocumentRead Successful Response
     * @throws ApiError
     */
    public static documentRead({
        id,
    }: {
        id: number,
    }): CancelablePromise<DocumentRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/{id}/',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Document Update
     * @returns DocumentRead Successful Response
     * @throws ApiError
     */
    public static documentUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: DocumentUpdate,
    }): CancelablePromise<DocumentRead> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/documents/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Document Archive
     * @returns DocumentRead Successful Response
     * @throws ApiError
     */
    public static documentArchive({
        id,
    }: {
        id: number,
    }): CancelablePromise<DocumentRead> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/documents/{id}/',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Document Read Public
     * @returns DocumentReadPublic Successful Response
     * @throws ApiError
     */
    public static documentReadPublic({
        publicId,
    }: {
        publicId: string,
    }): CancelablePromise<DocumentReadPublic> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/public/{public_id}/',
            path: {
                'public_id': publicId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Document Share
     * Enable or disable sharing on a document via public link.
     * @returns DocumentRead Successful Response
     * @throws ApiError
     */
    public static documentShare({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: ShareSettingsUpdate,
    }): CancelablePromise<DocumentRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/documents/{id}/share/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Document Unarchive
     * @returns DocumentRead Successful Response
     * @throws ApiError
     */
    public static documentUnarchive({
        id,
    }: {
        id: number,
    }): CancelablePromise<DocumentRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/documents/{id}/unarchive/',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Version List
     * @returns VersionRead Successful Response
     * @throws ApiError
     */
    public static versionList({
        skip,
        limit = 100,
        document,
    }: {
        skip?: number,
        limit?: number,
        document?: (number | null),
    }): CancelablePromise<Array<VersionRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/versions/',
            query: {
                'skip': skip,
                'limit': limit,
                'document': document,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Version Create
     * @returns VersionRead Successful Response
     * @throws ApiError
     */
    public static versionCreate({
        requestBody,
    }: {
        requestBody: VersionCreate,
    }): CancelablePromise<VersionRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/versions/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Version Read
     * @returns VersionRead Successful Response
     * @throws ApiError
     */
    public static versionRead({
        id,
    }: {
        id: number,
    }): CancelablePromise<VersionRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/versions/{id}/',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Version Update
     * @returns VersionRead Successful Response
     * @throws ApiError
     */
    public static versionUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: VersionUpdate,
    }): CancelablePromise<VersionRead> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Version Archive
     * @returns VersionRead Successful Response
     * @throws ApiError
     */
    public static versionArchive({
        id,
    }: {
        id: number,
    }): CancelablePromise<VersionRead> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/versions/{id}/',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Version Read Public
     * @returns VersionReadPublic Successful Response
     * @throws ApiError
     */
    public static versionReadPublic({
        publicId,
    }: {
        publicId: string,
    }): CancelablePromise<VersionReadPublic> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/versions/public/{public_id}/',
            path: {
                'public_id': publicId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Version Share
     * Enable or disable sharing on a version via public link.
     * @returns VersionRead Successful Response
     * @throws ApiError
     */
    public static versionShare({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: ShareSettingsUpdate,
    }): CancelablePromise<VersionRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/versions/{id}/share/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Version Unarchive
     * @returns VersionRead Successful Response
     * @throws ApiError
     */
    public static versionUnarchive({
        id,
    }: {
        id: number,
    }): CancelablePromise<VersionRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/versions/{id}/unarchive/',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
