<template>
  <main ref="scrollPanel" :data-panels="panelObjects.length">
    <BooksPanel v-for="(panel, idx) in panelObjects" :key="panel.id">
      <component
        :is="panel.component"
        :key="`panel-${panel.id}`"
        :panel-index="idx"
        v-bind="panel.props"
      />
    </BooksPanel>
    <Dashboard v-if="!panelObjects.length" />
  </main>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onUnmounted,
  ref,
  watch,
} from 'vue';

import Dashboard from '@/js/components/dashboard.vue';
import PanelNotFound from '@/js/components/panels/not-found.vue';
import BooksPanel from '@/js/components/panels/panel.vue';
import { getPanelDocOrVersionId, getPanelsFromRoute } from '@/js/router/panels';
import { type Panels, useRootStore } from '@/js/stores/root';
import { SCROLL_LEFT } from '@/js/utils/constants';
import ArchivePanelView from '@/js/views/app/panels/archive.vue';
import DocumentPanelView from '@/js/views/app/panels/document.vue';
import MyAccountPanelView from '@/js/views/app/panels/my-account.vue';
import VersionComparePanelView from '@/js/views/app/panels/version-compare.vue';
import VersionDetailPanelView from '@/js/views/app/panels/version-detail.vue';
import VersionListPanelView from '@/js/views/app/panels/version-list.vue';

export default defineComponent({
  name: 'WriteView',
  components: {
    ArchivePanelView,
    BooksPanel,
    Dashboard,
    DocumentPanelView,
    MyAccountPanelView,
    PanelNotFound,
    VersionComparePanelView,
    VersionDetailPanelView,
    VersionListPanelView,
  },
  props: {
    panelString: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const rootStore = useRootStore();
    const scrollPanel = ref<HTMLElement | null>(null);
    const panelObjects = computed(() => getPanelsFromRoute(props.panelString));

    const panels = computed(() => {
      const obj: Panels = {
        documents: [],
        versions: [],
        accounts: [],
        archives: [],
      };
      panelObjects.value.forEach((panel) => {
        const panelId = getPanelDocOrVersionId(panel);
        if (panelId !== null && panel.type) {
          obj[`${panel.type}s`].push(panelId);
        }
      });
      return obj;
    });

    const savePanels = () => {
      rootStore.panels = panels.value;
    };

    watch(panels, savePanels, { immediate: true });

    watch(
      () => props.panelString,
      async () => {
        if (history.state?.panelScroll === SCROLL_LEFT) {
          history.replaceState({ ...history.state, panelScroll: null }, '');
          await nextTick();
          if (scrollPanel.value) {
            scrollPanel.value.scrollLeft = 0;
          }
        }
      },
    );

    watch(
      panelObjects,
      () => {
        if (!panelObjects.value.length) {
          rootStore.navOpen = true;
        }
      },
      { immediate: true },
    );

    onUnmounted(() => {
      rootStore.closeAllPanels();
    });

    return { panelObjects, scrollPanel };
  },
});
</script>
