<template>
  <PanelHeader :panel-index="panelIndex">
    <template #menu>
      <PanelHeaderMenu :panel-index="panelIndex" />
    </template>

    <h2 class="panel-title-wrapper can-truncate">
      <span class="panel-title">Archive</span>
    </h2>
  </PanelHeader>

  <div class="content-wrapper">
    <div data-panel="scroll-content flex">
      <header class="list-header">
        <h2 class="list-title">My Archived Documents</h2>
      </header>
      <UtilityLoading v-if="fetchArchivedDocumentsState !== 'fetched'" />
      <div v-else-if="orderedArchivedDocuments.length" data-layout-list>
        <div
          v-for="document in orderedArchivedDocuments"
          :key="document.id"
          class="list-item"
          data-listitem="document archive"
        >
          <UtilityLink
            :href="`/write/document-${document.id}`"
            data-meta="docinfo"
            data-link="block"
            @click.prevent="openDocument(document.id)"
          >
            <VersionListItem
              :title="document.title"
              :modified-at="document.text_modified_at"
              :deleted-at="document.deleted || undefined"
              :word-count="document.word_count"
              :is-shared="document.public_id !== null"
              :connected="connected"
            />
          </UtilityLink>
          <button
            :disabled="!connected"
            data-btn
            class="restore"
            type="button"
            @click="restoreThenLoad(document.id)"
          >
            Restore
          </button>
        </div>
      </div>
      <span v-else data-message="empty">You have no archived documents.</span>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, storeToRefs } from 'pinia';
import { computed, defineComponent } from 'vue';

import PanelHeader from '@/js/components/panels/header.vue';
import PanelHeaderMenu from '@/js/components/panels/header-menu.vue';
import VersionListItem from '@/js/components/version/list-item.vue';
import useFetchArchives from '@/js/composables/useFetchArchives';
import { navigatePanel } from '@/js/router/panels';
import { useDocumentsStore } from '@/js/stores/documents';
import { useSocketStore } from '@/js/stores/socket';

export default defineComponent({
  name: 'PanelArchive',
  components: {
    PanelHeader,
    PanelHeaderMenu,
    VersionListItem,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const socketStore = useSocketStore();
    const documentsStore = useDocumentsStore();
    const connected = computed(() => socketStore.connected);
    useFetchArchives(true);
    const { orderedArchivedDocuments, fetchArchivedDocumentsState } =
      storeToRefs(documentsStore);

    return {
      connected,
      orderedArchivedDocuments,
      fetchArchivedDocumentsState,
    };
  },
  methods: {
    ...mapActions(useDocumentsStore, ['restoreDocument']),
    openDocument(documentId: number) {
      navigatePanel({
        to: `document-${documentId}`,
        panelIndex: this.panelIndex,
      });
    },
    async restoreThenLoad(documentId: number) {
      await this.restoreDocument(documentId);
      this.openDocument(documentId);
    },
  },
});
</script>
