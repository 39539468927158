<template>
  <PanelArchive :panel-index="panelIndex" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import PanelArchive from '@/js/components/panels/archive.vue';

export default defineComponent({
  name: 'ArchivePanelView',
  components: {
    PanelArchive,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
  },
});
</script>
